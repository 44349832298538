<template>
	<div>
		<div class="service">
			<innerNav></innerNav>
			<center><h1 id="title">{{ title }}</h1></center>
			<div class="main">
				<div v-for="service, i in services" :key="i" class="holder" data-aos="fade-up" data-aos-once="true" :data-aos-delay="200 * i/2">
					<h1 class="blue">{{ service.name }}</h1>
					<center>
						<span>{{ service.blurb }}</span>
					</center>
				</div>
			</div>
		</div>
		<bottom></bottom>
	</div>
</template>
<style scoped lang="scss">
.navigation.section{
	/* background-image: none; */
}
.services.component{
	overflow-x: hidden;
}
.main{
	min-height: 60vh;

	.holder{
		border: 1px solid #5c7d8a;
		background-color: rgba(255, 255, 255, 0.8);
	}

	h1{
		text-align: center;
		color: #5c7d8a;
		margin-bottom: 4px;
	}

	.item{
		font-size: 1.2rem;
		line-height: 1.5;
		orphans: 200;
	}
	.dot{
		color: #5c7d8a;
		margin: 0 1vw;	
	}
}
</style>
<style lang="scss">
	@import '@/assets/style.scss';
</style>
<script>
// @ is an alias to /src
import innerNav from '@/components/innerNav.vue'
import bottom from '@/components/bottom.vue'

export default {
	name: 'Service',
	data(){ return {
		services: [],
		title: ''
	}},
	components: {
		innerNav,
		bottom
	},
	mounted(){
		console.log(this.$route.params.service);
		import(`@/assets/services/${this.$route.params.service}.json`).then( item => {
			console.log(item);
			this.services = item.services;
			// AOS.init(); // Might animate this later 
			document.title = item.name + " • Straight Path IT Management";
			this.title = item.name;
		})
	}
}
</script>
